import axios from 'axios'
import { Api } from '../api/Api'

export default {
  logout: (context, caller) => {
    return new Promise((resolve, reject) => {
      axios(
        Api.baseUrl + '/logout',
        {
          method: 'get',
          withCredentials: true
        }
      ).then((response) => {
        context.commit('logout')
        resolve()
      })
    })
  },

  checkLoggedIn: (context) => {
    axios(
      Api.baseUrl + '/check_logged_in',
      {
        method: 'get',
        withCredentials: true
      }
    ).then((response) => {
      let userData = response.data
      context.commit('login', userData)
    }).catch(() => {
      context.commit('logout')
    })
  },
}
