
<template>
  <FeedbackReceived 
    v-if="loggedIn"
  >
  </FeedbackReceived>
  <div v-else>
    <v-main>
      <div class="text-center mt-10">
        <img style="height: 80px; border-radius: 5px; margin: 20px 0;" src='/static/frank-logo.jpg'/>
        <h1>Hi, there 👋</h1>
        <br/>
        <h2>Please sign in to get started</h2>
        <div class="mt-10">
          <a :href="authUrl">
            <img alt="'Sign in with Slack'" height="40" width="172" src="https://platform.slack-edge.com/img/sign_in_with_slack.png" srcset="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x" />
          </a>
        </div>
      </div>
    </v-main>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import { Api } from '@/api/Api'
import FeedbackReceived from './FeedbackReceived.vue'

export default {
  name: 'Home',
  components: {
    FeedbackReceived
  },
  props: {
  },
  data () {
    return {
      feedbackReceived: null,
      authUrl: null
    }
  },
  computed: {
    loggedIn: function () {
      return this.$store.state.loggedIn
    }
  },
  watch: {
  },
  methods: {
    fetchAuthUrl() {
      this.$store.state.appBarProgressActive = true

      let nextUrl = this.$route.query.nextUrl
      
      axios(
        Api.baseUrl + '/auth/get-auth-url',
        {
          method: 'post',
          data: { next: nextUrl },
          withCredentials: true
        }
      ).then((response) => {
        this.authUrl = response.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
  },
  mounted: function () {
    if (!this.loggedIn) {
      this.fetchAuthUrl()
    }
  },
  filters: {
  }
}
</script>

<style scoped>
</style>
