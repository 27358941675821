<template>
  <AppLoggedIn
    v-if="loggedIn"
  ></AppLoggedIn>
  <AppLoggedOut
    v-else
  ></AppLoggedOut>
</template>

<script>
// import NavigationDrawer from './components/NavigationDrawer.vue'
import AppLoggedIn from './views/AppLoggedIn.vue'
import AppLoggedOut from './views/AppLoggedOut.vue'

import { mapActions } from 'vuex'

export default {
  data: function () {
    return {
      loginDialogToggle: false,
      showDrawer: false
    }
  },
  computed: {
    loggedIn: function () {
      return this.$store.state.loggedIn
    }
  },
  methods: {
    ...mapActions({
      checkLoggedIn: 'checkLoggedIn',
      logoutAction: 'logout'
    }),
    logout () {
      this.logoutAction(this)
        .then(() => {
          this.$router.push('/')
          location.reload()
        })
    },
  },
  components: {
    AppLoggedIn,
    AppLoggedOut
  },
  mounted () {
    this.checkLoggedIn()
  }
}
</script>

<style lang="scss">
@import 'https://cdn.jsdelivr.net/npm/@mdi/font@3.x/css/materialdesignicons.min.css';
::-webkit-scrollbar {display:none;}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  font-size: 14px;
  font-weight: 400;
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.search-box {
  padding-right: 10px;
  max-width: 400px;
  width: 50%;
}

.site-name {
  //  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 20px;
  margin-right: 10px;
}

.no-uppercase {
     text-transform: none;
}

.title-div {
  text-align: left;
  margin-left: 12px;
}

.title-div h1 {
  font-size: 22px;
}

.subtitle-gray {
  color: darkgray!important;
}

.v-btn {
  text-transform: none!important;
}

i.v-icon.v-icon {
} 

.home {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto
}

@media only screen and (max-width: 600px) {
  /* css for xs screens */
  .title-div h1 {
    font-size: 18px;
  }

  .home {
    overflow-x: hidden;
  }

}

</style>
