<template>
  <v-container
    v-if="$store.state.userData.is_admin == true"
    fluid style="max-width: 800px; width: 90%;"
  >
    <div class="text-left">
      <h2 class="mt-5">Subscription</h2>    
    </div>
    <v-container flex>
      <v-row>
        <v-col md="6">
          <div v-if="planInfo != null" class="mt-3">
            <p><b>Current plan: </b> {{ planInfo.plan_name }} </p> 
            <p v-if="planInfo.plan == subscription_types.SUBSCRIPTION_TRIAL">
              <b>Trial end time: </b>{{ planInfo.trial_end_time | moment("MMMM DD, YYYY") }}<span v-if="planInfo.is_trial_expired" style="color: red;">(Expired)</span>
            </p>
            <div v-if="planInfo.plan == subscription_types.SUBSCRIPTION_GROUP">
              <div v-if="planInfo.subscription_status == 'active'">
                <p>
                  <b>Next payment:</b> ${{ (planInfo.users_in_subscription.length * planInfo.monthly_price_per_user) | numeral('0.00') }} on {{ planInfo.next_payment_time | moment("MMMM DD, YYYY") }}
                </p>
              </div> 
              <div v-else>
                <p>
                  <b>Status:</b> Canceled, active until {{ planInfo.next_payment_time | moment("MMMM DD, YYYY") }}
                </p>
              </div> 
            </div>
          </div>
        </v-col>
        <v-col md="6" align-self="start" class="text-right">
            <v-btn
              v-if="!planInfo.subscription_exists"
              color="error"
              @click="showAddUsersDialog"
            >
              <b>Upgrade now</b>
            </v-btn>
            <v-btn
              v-else-if="planInfo.subscription_exists && planInfo.subscription_status ==='active'"
              color="error"
              @click="showAddUsersDialog"
            >
              <b>+ Add users</b>
            </v-btn>
            <v-btn
              v-else-if="planInfo.subscription_exists && planInfo.subscription_status ==='canceled'"
              color="error"
              @click="reactivateSubscriptionConfirmationDialogVisible=true"
            >
              <b>Reactivate subscription</b>
            </v-btn>

            <div v-if="planInfo.subscription_exists && planInfo.subscription_status==='active'">
              <div class="mt-5">
                <a :href="planInfo.update_payment_url" target="_blank">Change payment method</a> 
              </div>
              <div class="mt-1">
                <a @click.prevent="cancelSubscriptionConfirmationDialogVisible = true" href="">Cancel subscription</a> 
              </div>
            </div>
        </v-col>
      </v-row>
      <v-dialog
        v-model="cancelSubscriptionConfirmationDialogVisible"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            Please confirm
          </v-card-title>
          <v-card-text style="color: black;">
            Cancel subscription?<br/><br/>
            Your subscription will remain active until {{ planInfo.next_payment_time | moment("MMMM DD, YYYY")  }}. No additional charges will be made.
            <div class="mt-1" style="color: red" v-if="errorText !=null"><b>{{ errorText }}</b></div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn @click="cancelSubscriptionConfirmationDialogVisible=false">Not yet</v-btn>
            <v-btn @click="cancelSubscription" color="error">Cancel subscription</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="reactivateSubscriptionConfirmationDialogVisible"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            Please confirm
          </v-card-title>
          <v-card-text style="color: black;">
            Reactivate subscription?<br/><br/>
            No charges will be made before your next billing cycle - {{ planInfo.next_payment_time | moment("MMMM DD, YYYY")  }}. 
            <div class="mt-1" style="color: red" v-if="errorText !=null"><b>{{ errorText }}</b></div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn @click="reactivateSubscriptionConfirmationDialogVisible=false">Not yet</v-btn>
            <v-btn @click="reactivateSubscription" color="error">Reactivate subscription</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <div v-if="planInfo.subscription_exists">
      <div class="text-left">
        <h2 class="mt-5">Users included in subscription ({{ planInfo.users_in_subscription.length }})</h2>    
      </div>
      <v-simple-table class="text-left mb-10">
        <template v-slot:default>
          <tbody>
            <tr v-for="(user, idx) in planInfo.users_in_subscription" :key="idx">
              <td>
                <img class="user-icon" :src="user.user_info.profile.image_32"/> {{ user.user_info.profile.real_name }}
                </td>
              <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon @click="confirmDelete(user)">mdi-minus-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>Remove from subscription</span>
              </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-dialog
        v-if="userToDelete != null"
        v-model="deleteUserConfirmDialogVisible"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            Please confirm
          </v-card-title>
          <v-card-text style="color: black;">
            Remove <b>{{ userToDelete.user_info.profile.real_name }}</b> from subscription?
            <div class="mt-1" style="color: red" v-if="errorText !=null"><b>{{ errorText }}</b></div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn @click="deleteUserConfirmDialogVisible=false">Cancel</v-btn>
            <v-btn @click="removeUserFromSubscription(userToDelete)" color="error">Remove</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>


    <SubscriptionUsersDialog
      :showDialogToggle="addUsersDialogVisible"
      :planInfo="planInfo"
      :prorateInfo="prorateInfo"
      @userSelectionDone="onUserSelectionDone"
    ></SubscriptionUsersDialog> 

    <PaymentDetailsDialog
      :showDialogToggle="paymentDetailsDialogVisible"
      @paymentInfoAdded="paymentInfoAdded"
    ></PaymentDetailsDialog>

    <ReviewAndPayDialog
      :showDialogToggle="reviewAndPayDialogVisible"
      :selectedUsers="selectedUsers"
      :planInfo="planInfo"
      :prorateInfo="prorateInfo"
      @usersAddedToSubscription="onUsersAddedToSubscription"
    ></ReviewAndPayDialog>

    <v-snackbar
      app
      bottom right
      timeout="1200"
      color="info"
      v-model="showSnackbar"
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import { Api } from '@/api/Api'
import { SUBSCRIPTION_TYPES } from '../Constants'
import PaymentDetailsDialog from '../components/Payment/PaymentDetailsDialog.vue'
import ReviewAndPayDialog from '../components/Payment/ReviewAndPayDialog.vue'
import SubscriptionUsersDialog from '../components/Payment/SubscriptionUsersDialog.vue'

export default {
  name: 'AdminPlans',
  components: {
    PaymentDetailsDialog,
    ReviewAndPayDialog,
    SubscriptionUsersDialog
  },
  props: {
  },
  data () {
    return {
      planInfo: null,
      prorateInfo: null,

      addUsersDialogVisible: false,
      selectedUsers: null,

      paymentDetailsDialogVisible: false,
      paymentDone: false,

      reviewAndPayDialogVisible: false,
      fetchSubscriptionRetry: null,
      fetchSubscriptionRetryInProgress: false,

      deleteUserConfirmDialogVisible: null,
      userToDelete: null,
      errorText: null,

      cancelSubscriptionConfirmationDialogVisible: false,
      reactivateSubscriptionConfirmationDialogVisible: false,

      showSnackbar: false,
      snackbarText: ''
    }
  },
  computed: {
    subscription_types() {
      return SUBSCRIPTION_TYPES
    },
  },
  watch: {
  },
  methods: {
    fetchPlanInfo() {
      this.$store.state.appBarProgressActive = true
      return new Promise((resolve, reject) => {
        axios(
          Api.baseUrl + '/get-plan-info',
          {
            method: 'post',
            data: {},
            withCredentials: true
          }
        ).then((response) => {
          this.planInfo = response.data

          this.planInfo.users_in_subscription.sort((a, b) => {
            if (a.user_info.profile.real_name < b.user_info.profile.real_name) {
              return -1
            } else if (a.user_info.profile.real_name > b.user_info.profile.real_name) {
              return 1
            } else {
              return 0
            }
          })
          resolve(response)
        }).catch((error) => {
          console.log(error)
          reject(error)
        }).finally(() => {
          this.$store.state.appBarProgressActive = false
        })
      })
    },
    fetchProrate () {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-prorate-info',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        this.prorateInfo = response.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    confirmDelete (user) {
      this.userToDelete = user
      this.deleteUserConfirmDialogVisible = true
    },
    removeUserFromSubscription (user) {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/remove-user-from-subscription',
        {
          method: 'post',
          data: { 'user_id': user.id },
          withCredentials: true
        }
      ).then((response) => {
        this.deleteUserConfirmDialogVisible = false
        this.fetchPlanInfo()
        this.snackbarText = "User removed from subscription"
        this.showSnackbar = true
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    isInSubscription (user) {
      if (this.planInfo.users_in_subscription == undefined) {
        return false
      }
      for (let i = 0; i < this.planInfo.users_in_subscription.length; i++) {
        if (user.id===this.planInfo.users_in_subscription[i].user_info['id']) {
          return true;
        }
      }
      return false;
    },
    showAddUsersDialog () {
      this.fetchPlanInfo()
      this.fetchProrate()
      this.addUsersDialogVisible = !this.addUsersDialogVisible
    },
    onUserSelectionDone (selectedUsers) {
      this.selectedUsers = selectedUsers
      this.addUsersDialogVisible = !this.addUsersDialogVisible
      if (this.planInfo.subscription_exists === false) {
        this.toPaymentDetails()
      } else {
        this.toReviewBilling()
      }
    },
    toPaymentDetails () {
      this.paymentDetailsDialogVisible = !this.paymentDetailsDialogVisible
    },
    paymentInfoAdded (data) {
      this.fetchSubscriptionRetry = setInterval(
        () => {
          if (!this.fetchSubscriptionRetryInProgress) {
            this.fetchSubscriptionRetryInProgress = true
            this.$store.state.appBarProgressActive = true

            this.fetchPlanInfo().then(() => {
              this.$store.state.appBarProgressActive = false
              if (this.planInfo.subscription_exists) {
                clearInterval(this.fetchSubscriptionRetry)
                this.paymentDetailsDialogVisible = !this.paymentDetailsDialogVisible
                this.toReviewBilling()
              }
            }).finally(() => {
              this.fetchSubscriptionRetryInProgress = false
            })
          }
        },
        200
      )
    },
    toReviewBilling (data) {
      this.reviewAndPayDialogVisible = !this.reviewAndPayDialogVisible
    },
    onUsersAddedToSubscription() {
      this.snackbarText = "Users added to subscription"
      this.showSnackbar = true
      this.fetchPlanInfo()
    },
    cancelSubscription () {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/cancel-subscription',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        this.fetchPlanInfo()
        this.cancelSubscriptionConfirmationDialogVisible = false
        this.snackbarText = 'Subscription canceled.'
        this.showSnackbar = true
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    reactivateSubscription () {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/reactivate-subscription',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        this.fetchPlanInfo()
        this.reactivateSubscriptionConfirmationDialogVisible = false
        this.snackbarText = 'Subscription reactivated.'
        this.showSnackbar = true
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    }
  },
  mounted: function () {
    this.fetchPlanInfo()
    this.fetchProrate()
  },
}
</script>

<style scoped>
  .card-text {
    color: black!important;
  }

</style>
