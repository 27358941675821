var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.userData.is_admin == true)?_c('v-container',{staticStyle:{"text-align":"center","max-width":"800px","width":"90%"},attrs:{"fluid":""}},[_c('div',{staticStyle:{"text-align":"left"}},[_c('v-treeview',{ref:"treeview",attrs:{"items":_vm.org,"expand-icon":"mdi-chevron-down","item-children":"sub_org","item-text":"user_info.real_name","transition":"","hoverable":"","open-all":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"user-icon",attrs:{"src":item.user_info.profile.image_32}})]}},{key:"append",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.user_id != -1)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){item.sub_org.length == 0 ? _vm.deleteSubordinate(item) : _vm.deleteSubordinateConfirm(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":""}},[_vm._v("mdi-minus-circle-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Remove "+_vm._s(item.user_info.profile.real_name))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editSubordinatesClicked(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":""}},[_vm._v("mdi-account-multiple-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Add direct reports")])])]}}],null,false,995363031)})],1),_c('EditSubordinatesDialog',{attrs:{"showLoginToggle":_vm.editSubordinatesDialogVisible,"manager":_vm.selectedUser},on:{"orgChartUpdated":function($event){return _vm.fetchOrgChart()}}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.deleteSubordinateConfirmDialogVisible),callback:function ($$v) {_vm.deleteSubordinateConfirmDialogVisible=$$v},expression:"deleteSubordinateConfirmDialogVisible"}},[(_vm.subordinateToDelete != null)?_c('v-card',[_c('v-card-title',[_vm._v(" Confirm delete ")]),_c('v-card-text',[_vm._v(" Delete "),_c('div',{staticClass:"user-chip"},[_c('img',{attrs:{"src":_vm.subordinateToDelete.user_info.profile.image_48}}),_vm._v(" "+_vm._s(_vm.subordinateToDelete.user_info.profile.real_name)+" ")]),_vm._v(" with their entire sub-org? ")]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{on:{"click":function($event){_vm.deleteSubordinateConfirmDialogVisible=false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteSubordinate(_vm.subordinateToDelete)}}},[_vm._v("Delete")])],1)],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }