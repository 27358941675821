<template>
  <v-dialog
    v-model="dialogVisible"
    max-width="600px"
    overlay-opacity="0.8"
    scrollable
    persistent
  >
    <v-card>
      <v-progress-linear
        :active="$store.state.appBarProgressActive"
        :indeterminate="$store.state.appBarProgressActive"
        absolute
        color="orange"
      ></v-progress-linear> 
      <v-card-title>
        Review &amp; Billing info
        <v-spacer/>
        <a
          class="back-button"
          @click="dialogVisible = false"
        ><v-icon>mdi-close</v-icon>
        </a>
      </v-card-title>
      <v-card-text class="card-text">
        <div class="mt-2" style="color: black">
          <div>{{ newUsers.length }} new users</div>
          <div v-if="(newUsers.length > 0 && !prorateInfo.prorate_applies) ? true : false">
            x ${{ planInfo.monthly_price_per_user | numeral('0.00') }} per user / month
            <hr class="my-2"/>
            <b>Total <span v-if="planInfo.subscription_exists">additional</span> cost: ${{ (newUsers.length * planInfo.monthly_price_per_user) | numeral('0.00') }}/month</b>
          </div>
          <div v-if="(newUsers.length > 0 && prorateInfo.prorate_applies) ? true : false">
            x ${{ planInfo.monthly_price_per_user | numeral('0.00') }} per user / month
            <hr class="my-2"/>
            <b>Total <span v-if="planInfo.subscription_exists">additional</span> cost:</b> <br/>
            <b>${{ (newUsers.length * prorateInfo.prorated_price_per_user) | numeral('0.00') }} one-time payment, prorated for {{ prorateInfo.days_until_next_payment }} days</b> until {{ planInfo.next_payment_time | moment("MMMM DD, YYYY")}}<br/>
            <b>${{ (newUsers.length * planInfo.monthly_price_per_user) | numeral('0.00') }}/month</b> after {{ planInfo.next_payment_time | moment("MMMM DD, YYYY")}}
          </div>
        </div>
        <div v-if="newUsers.length > 0">
          <div class="mt-5">
            New users:
          </div>
          <div class="" style="max-height: 300px;  border-radius: 5px; border: 1px solid lightgray; padding: 5px; overflow: scroll">
            <div v-for="(user, idx) in newUsers" :key="idx" style="display: inline-block;">
              <v-chip
                class="my-1 ml-0 mr-1"
                label
                color="#d2f8d2"
              >
                <img class="user-icon mr-1" :src="user.profile.image_32"/>
                {{ user.profile.real_name }}
              </v-chip>
            </div>
          </div>

        </div>
      </v-card-text>
      <v-card-actions class="justify-end mr-2 mb-3" >
        <v-btn
          color="primary"
          @click="addUsersToSubscription()"
          :disabled="newUsers.length > 0 ? false : true"
          >Confirm and pay</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import { Api } from '@/api/Api'

export default {
  name: 'ReviewAndPayDialog',
  components: {
  },
  props: {
    showDialogToggle: {
      type: Boolean,
      default: false
    },
    selectedUsers: {
      type: Array,
      default: () => {return []}
    },
    planInfo: Object,
    prorateInfo: Object
  },
  data () {
    return {
      dialogVisible: false,
      paymentDone: false,

      subscriptionInfo: null
    }
  },
  computed: {
    newUsers: function () {
      let users = []
      if (this.selectedUsers != null) {
        this.selectedUsers.map((user) => {
          if(this.isInSubscription(user) === false) {
            users.push(user)
          }
        })
      }
      return users
    }
  },
  watch: {
    showDialogToggle (oldVal, newVal) {
      this.dialogVisible = !this.dialogVisible
      this.showError = false
    },
    dialogVisible (newVal, oldVal) {
      if (oldVal == false && newVal == true) {
        this.fetchSubscription()
      }
    },
  },
  methods: {
    fetchSubscription () {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-subscription',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        this.subscriptionInfo = response.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    addUsersToSubscription () {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/add-users-to-subscription',
        {
          method: 'post',
          data: {
            new_users: this.newUsers
          },
          withCredentials: true
        }
      ).then((response) => {
        this.dialogVisible = false 
        this.$emit('usersAddedToSubscription')  
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    isInSubscription (user) {
      if (this.planInfo.users_in_subscription == undefined) {
        return false
      }

      for (let i = 0; i < this.planInfo.users_in_subscription.length; i++) {
        if (user.id===this.planInfo.users_in_subscription[i].user_info['id']) {
          return true;
        }
      }
      return false;
    },
  },
  mounted: function () {
  },
  filters: {
  }
}
</script>

<style scoped>
  .user-chip {
    background-color: rgb(245, 245, 245);
    display: inline-block;
    padding: 5px 6px;
    border-radius: 5px;
  }

  .user-icon {
    height: 18px;
    border-radius: 3px;
    vertical-align: bottom;
  }

  a.back-button {
    font-size: 12px;
  }

  .card-text {
    color: black!important;
  }
</style>

