
<template>
  <v-container fluid class="main-container" >
    <h1>Hi, {{ $store.state.userData.real_name }} 👋</h1>
    <h2>This is your feedback wall</h2>
    <br/>
    <div v-if="feedbackReceived != null && feedbackReceived.length == 0" style="font-size: 16px;">
      Nothing here yet.
    </div>
    <div v-else v-for="(feedback, idx) in feedbackReceived" :key="idx" class="feedback-card mb-3 px-5 py-5">
      <FeedbackCard
        :feedback="feedback"
      ></FeedbackCard>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import { Api } from '@/api/Api'
import FeedbackCard from '../components/FeedbackView/FeedbackCard.vue'

export default {
  name: 'FeedbackReceived',
  components: {
    FeedbackCard
  },
  props: {
  },
  data () {
    return {
      feedbackReceived: null
    }
  },
  computed: {
    feedback_types() {
      return FEEDBACK_TYPES
    }
  },
  watch: {
  },
  methods: {
    fetchFeedbackReceived () {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-feedback-received',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        this.feedbackReceived = response.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    }
  },
  mounted: function () {
    this.fetchFeedbackReceived()
  },
  filters: {
  }
}
</script>

<style scoped>
  .feedback-card-header {
    font-weight: 500;
  }

</style>
