<template>
  <div>
    <div class="mt-3 mb-2">
      <h2>{{ feedback.subject }}</h2>
    </div>
    <div class="feedback-content"> 
      <h3>Observation</h3>
      <p v-for="(paragraph, idx) in feedback.content.observation_text.split('\n')" :key="idx">{{ paragraph }} </p>
    </div>
    <div class="feedback-content"> 
      <h3>Feeling</h3>
      <p v-for="(paragraph, idx) in feedback.content.feeling_text.split('\n')" :key="idx">{{ paragraph }} </p>
    </div>
    <div class="feedback-content"> 
      <h3>Need</h3>
      <p v-for="(paragraph, idx) in feedback.content.need_text.split('\n')" :key="idx">{{ paragraph }} </p>
    </div>
    <div class="feedback-content"> 
      <h3>Request</h3>
      <p v-for="(paragraph, idx) in feedback.content.request_text.split('\n')" :key="idx">{{ paragraph }} </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NVC',
  components: {
  },
  props: {
    feedback: Object
  },
  data () {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
  mounted: function () {
  },
  filters: {
  }
}
</script>

<style scoped>

</style>