import state from "./state"

export default {
  login (state, userData, ssoProvider = null) {
    state.loggedIn = true
    state.userData = userData
    state.ssoProvider = ssoProvider
    state.userFirstTimeUX = userData.ftue
  },
  logout (state) {
    state.loggedIn = false
  },
}
