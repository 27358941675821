<template>
  <v-navigation-drawer
    app
    flat
    clipped
    bottom
    color="white"
    :mini-variant="compact"
    :expand-on-hover="compact"
    v-model="visibleToggle"
    style="overflow-y: auto"
  >
    <template v-slot:prepend>
      <v-list dense>
        <v-list-item-group>
          <v-list-item
            to='/'
            >
            <v-list-item-icon>
              <v-icon :color="menuIconColor">mdi-email-receive</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Received  
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to='/feedback-sent'
            >
            <v-list-item-icon>
              <v-icon :color="menuIconColor">mdi-email-send</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <span
                style="text-align: left"
              >
                Sent 
              </span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to='/praise-wall'
            >
            <v-list-item-icon>
              <v-icon :color="menuIconColor">mdi-star-shooting</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <span
                style="text-align: left"
              >
                Wall of Praise
              </span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="$store.state.userData.has_reports"
            to='/my-team'
            >
            <v-list-item-icon>
              <v-icon :color="menuIconColor">mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <span
                style="text-align: left"
              >
                Your Team
              </span>
            </v-list-item-content>
          </v-list-item>
          <div v-if="$store.state.userData.is_admin">
            <v-divider></v-divider>
            <v-subheader
            >Admin</v-subheader>
          
            <v-list-item
              to='/admin-values'
            >
              <v-list-item-icon>
                <v-icon :color="menuIconColor">mdi-medal</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span
                  style="text-align: left"
                >
                  Praise &amp; Values
                </span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              to='/org-chart'
            >
              <v-list-item-icon>
                <v-icon :color="menuIconColor">mdi-file-tree</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span
                  style="text-align: left"
                >
                  Org Chart
                </span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              to='/admin-admins'
            >
              <v-list-item-icon>
                <v-icon :color="menuIconColor">mdi-account-key</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span
                  style="text-align: left"
                >
                  Admins
                </span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              to='/admin-plans'
            >
              <v-list-item-icon>
                <v-icon :color="menuIconColor">mdi-card-account-details</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span
                  style="text-align: left"
                >
                  Subscription Plan
                </span>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </template>
    <template
      v-slot:append
      v-if="!compact"
    >
      <div class="footer mb-3">
        <a href="https://imfrank.app/how-to/getting-started">How-to</a>&nbsp;&nbsp;
         |<a href="https://imfrank.app/blog">Blog</a>&nbsp;&nbsp;
        <br/>
        <span>Support: help@imfrank.app</span>&nbsp;&nbsp;
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'NavigationDrawer',
  data () {
    return {
      visibleToggle: false,
      menuIconColor: ''
    }
  },
  computed: {
    hasUnseenSubscriptions: function () {
      return this.$store.state.userHasUnseenSubscriptions
    }
  },
  props: {
    visible: Boolean,
    compact: Boolean
  },
  methods: {
  },
  watch: {
    visible (oldValue, newValue) {
      this.visibleToggle = !this.visibleToggle
    }
  },
  mounted: function () {
  }
}
</script>

<style>
  .footer a, .footer span {
    text-decoration: none;
    color: black;
    font-size: 12px;
    margin-left: 0.5em;
  }


  .footer a:visited {
    color: black;
  }

  .menu-icon {
    color: blue;
  }
</style>
