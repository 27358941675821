import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import FeedbackSent from './views/FeedbackSent.vue'
import PraiseWall from './views/PraiseWall.vue'
import PraiseWallPublic from './views/PraiseWallPublic.vue'
import OrgChart from './views/OrgChart.vue'
import MyTeam from './views/MyTeam.vue'
import AdminValues from './views/AdminValues.vue'
import AdminAdmins from './views/AdminAdmins.vue'
import AdminPlans from './views/AdminPlans.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/feedback-sent',
      name: 'feedback-sent',
      component: FeedbackSent 
    },
    {
      path: '/praise-wall',
      name: 'praise-wall',
      component: PraiseWall
    },
    {
      path: '/praise-wall-public/:token',
      name: 'praise-wall-public',
      component: PraiseWallPublic
    },
    {
      path: '/org-chart',
      name: 'org-chart',
      component: OrgChart
    },
    {
      path: '/my-team',
      name: 'my-team',
      component: MyTeam
    },
    {
      path: '/admin-values',
      name: 'admin-values',
      component: AdminValues
    },
    {
      path: '/admin-admins',
      name: 'admin-admins',
      component: AdminAdmins
    },
    {
      path: '/admin-plans',
      name: 'admin-plans',
      component: AdminPlans
    },
  ]
})
