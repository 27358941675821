const FEEDBACK_TYPES = Object.freeze({
  OPEN_FEEDBACK: 1,
  PRAISE: 2,
  START_STOP_CONTINUE: 3,
  SITUATION_BEHAVIOR_IMPACT: 4,
  NVC: 5
})

const SUBSCRIPTION_TYPES = Object.freeze({
  SUBSCRIPTION_FREE : 0,
  SUBSCRIPTION_EXPIRED : 1,
  SUBSCRIPTION_GROUP : 2,
  SUBSCRIPTION_WORKSPACE : 3,
  SUBSCRIPTION_TRIAL : 4
})


export { FEEDBACK_TYPES, SUBSCRIPTION_TYPES }
