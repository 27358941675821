<template>
  <div
    v-if="$store.state.loggedIn === false"
    id="app"
  >
    <v-app>
      <v-app-bar
        v-if="!$store.state.appBarOverride"
        app
        flat
        clipped-left
      >
        <router-link
          class="site-name" to="/" tag="span" style="cursor: pointer"
        >
          <a class="header-link">
            <img style="height: 40px; border-radius: 5px; margin-top: 7px;" src='/static/frank-logo.jpg'/>
          </a>
        </router-link>
        <div style="display: inline-block; font-weight: 600; font-size: 20px;" class="ml-0 pb-0"> I'm Frank</div>
        <v-spacer></v-spacer>
        <div>
          <a href="https://slack.com/oauth/v2/authorize?user_scope=identity.basic,identity.team,identity.avatar&client_id=1893071180962.2213955319330&redirect_uri=https%3A%2F%2Fdash.imfrank.app%2Fauth%2Fslack-oauth-user">
            <img alt="'Sign in with Slack'" height="40" width="172" src="https://platform.slack-edge.com/img/sign_in_with_slack.png" srcset="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x" />
          </a>
        </div>
        <v-menu
          v-if="loggedIn"
          offset-y
          bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :icon="$vuetify.breakpoint.mobile"
                :text="!$vuetify.breakpoint.mobile"

                small
                v-on="on"
                class="article-menu-button"
              >
                <v-icon>mdi-account</v-icon>
                <span
                  v-if="!$vuetify.breakpoint.mobile"
                >
                  &nbsp;{{ $store.state.userData.name }}
                </span>
              </v-btn>
            </template>
          <v-list dense>
          </v-list>
        </v-menu>
        <v-progress-linear
          :active="$store.state.appBarProgressActive"
          :indeterminate="$store.state.appBarProgressActive"
          absolute
          bottom
          color="orange"
        ></v-progress-linear> 
      </v-app-bar>
      <v-main>
          <router-view></router-view>
      </v-main>

      <v-footer>
      </v-footer>
  </v-app>
  </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  data: function () {
    return {
      showDrawer: false,
      searchBoxVisible: false
    }
  },
  computed: {
    loggedIn: function () {
      return this.$store.state.loggedIn
    },
    loginDialogToggle: function () {
      return true
    }
  },
  methods: {
    ...mapActions({
      checkLoggedIn: 'checkLoggedIn',
      logoutAction: 'logout',
    }),
    logout () {
      this.logoutAction(this)
        .then(() => {
          this.$router.push('/')
          location.reload()
        })
    },
  },
  components: {
  },
  mounted () {
    this.checkLoggedIn()
  }
}
</script>

<style>
  .blogboard-red {
    color: #fd3a5c;
  }

  .footer {
    text-align: center;
    width: 100%;
  }

  .footer a {
    text-decoration: none;
    color: black!important;
    font-size: 12px;
  }

  .footer a:visited {
    color: black!important;
  }

  .search-box {
    margin-left: auto;
    margin-right: auto;
  }

  a.header-link {
    text-decoration: none;
    color: black!important;
  }

  a.header-link:visited {
    text-decoration: none;
    color: black!important;
  }

  a.header-link-right {
    font-size: 16px;
    font-weight: 400;
  }

  a.header-link .v-icon {
    font-size: 36px!important;
  }

</style>
