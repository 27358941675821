<template>
  <div>
    <div class="feedback-content my-6"> 
      <p v-for="(paragraph, idx) in feedback.content.text.split('\n')" :key="idx">{{ paragraph }} </p>
    </div>
    <div> 
      <span class="value-chip mr-1 mt-1" v-for="(value, idx) in feedback.values" :key="idx"><b>{{ value }}</b> </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Praise',
  components: {
  },
  props: {
    feedback: Object
  },
  data () {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
  mounted: function () {
  },
  filters: {
  }
}
</script>

<style scoped>
  .value-chip {
    background-color: rgb(255, 250, 210);
    display: inline-block;
    padding: 5px 6px;
    border-radius: 5px;
  }

</style>