<template>
  <v-dialog
    v-model="dialogVisible"
    max-width="600px"
    overlay-opacity="0.8"
    scrollable
    persistent
  >
    <v-card v-if="manager != null">
      <v-progress-linear
        :active="$store.state.appBarProgressActive"
        :indeterminate="$store.state.appBarProgressActive"
        absolute
        color="orange"
      ></v-progress-linear> 
      <v-card-title>
        Add direct reports
        <v-spacer/>
        <a
          class="back-button"
          @click="dialogVisible = false"
        ><v-icon>mdi-close</v-icon>
        </a>
      </v-card-title>
      <v-card-text>
        For <div class="user-chip">
          <img :src="manager.user_info.profile.image_48"/> {{ manager.user_info.profile.real_name }}
        </div>
        <v-autocomplete
          class="mt-3"
          v-model="autocompleteModel"
          :items="allPeople"
          :search-input.sync="searchInput"
          color="white"
          item-text="profile.real_name"
          item-value="user_id"
          label="Find people to add"
          placeholder="People"
          no-data-text="Search by name"
          return-object
          solo
          dense
          chips
          small-chips
          multiple
        >
          <template v-slot:item="data">
            <img class="user-icon" :src="data.item.profile.image_48">
            <v-list-item-title class="ml-1" v-html="data.item.profile.real_name"></v-list-item-title>
          </template>
          <template v-slot:selection="data">
            <v-chip
              class="my-1 ml-0 mr-1"
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              label
              color="rgb(245, 245, 245)"
              @click="data.select"
              @click:close="remove(data.item)"
            >
              <img class="user-icon mr-1" :src="data.item.profile.image_32"/>
              {{ data.item.profile.real_name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-text v-if="showError">
        <div style="color: red">
          <v-icon color="red">mdi-alert-circle</v-icon> {{ errorText }}
        </div>
      </v-card-text>
      <v-card-actions class="justify-end mr-2 mb-3" >
        <v-btn
          color="primary"
          @click="applyOrgChanges()"
          >Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import { Api } from '@/api/Api'

export default {
  name: 'EditSubordinatesDialog',
  components: {
  },
  props: {
    showLoginToggle: {
      type: Boolean,
      default: false
    },
    manager: {
      type: Object
    }
  
  },
  data () {
    return {
      dialogVisible: false,
      directReports: [],
      allPeople: null,
      autocompleteModel: null,
      searchInput: null,
      showError: false,
      errorText: ''
    }
  },
  computed: {
  },
  watch: {
    showLoginToggle (oldVal, newVal) {
      this.dialogVisible = !this.dialogVisible
      this.showError = false
    },
    dialogVisible (newVal, oldVal) {
      if (oldVal == false && newVal == true) {
        this.searchInput = null
        this.directReports = []
        this.autocompleteModel = []
      }
    },
    autocompleteModel (newVal, oldVal) {
      if (newVal != null) {
        this.directReports = []
        newVal.map((item) => {
          this.directReports.push({
            user_info: item
          })
        })
      }
    },
  },
  methods: {
    remove (item) {
      let ind = this.autocompleteModel.indexOf(item)
      if (ind >= 0) {
        this.autocompleteModel.splice(ind, 1)
      }
    },
    fetchDirectReports() {
      this.directReports = []
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-org-chart',
        {
          method: 'post',
          data: { 
            user_id: this.manager.user_id == -1 ? null : this.manager.user_id,
            max_depth: 1
          },
          withCredentials: true
        }
      ).then((response) => {
        if (this.manager.user_id == -1) {
          this.directReports = response.data
        } else {
          this.directReports = response.data.sub_org
        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    fetchAllPeople() {
      this.allPeople = []
      axios(
        Api.baseUrl + '/get-all-users',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        response.data.sort((a, b) => {
          if (a.profile.real_name < b.profile.real_name) {
            return -1
          } else if (a.profile.real_name > b.profile.real_name) {
            return 1
          } else {
            return 0
          }
        })
        this.allPeople = response.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    removeDirectReport (report) {
      var ind = 0;
      for (let i = 0; i < this.directReports.length; i++) {
        if (this.directReports[i].user_info.id == report.user_info.id) {
          ind = i;
          break;
        }
      }
      this.directReports.splice(ind, 1)
    },
    applyOrgChanges () {
      this.$store.state.appBarProgressActive = true
      this.showError = false
      axios(
        Api.baseUrl + '/add-direct-reports',
        {
          method: 'post',
          data: {
            manager: this.manager,
            reports: this.directReports
          },
          withCredentials: true
        }
      ).then((response) => {
        if (response.data.ok === false) {
          this.errorText = response.data.error
          this.showError = true
        } else {
          this.$emit('orgChartUpdated')
          this.dialogVisible = false
        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    }
  },
  mounted: function () {
    this.fetchAllPeople()
  },
  filters: {
  }
}
</script>

<style scoped>
  .user-chip {
    background-color: rgb(245, 245, 245);
    display: inline-block;
    padding: 5px 6px;
    border-radius: 5px;
  }

  .user-icon {
    height: 18px;
    border-radius: 3px;
    vertical-align: bottom;
  }

  a.back-button {
    font-size: 12px;
  }
</style>
