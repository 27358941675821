
<template>
  <v-container fluid class="main-container">
    <v-autocomplete
      class="mt-3"
      v-model="autocompleteModel"
      :items="teamMembers"
      :search-input.sync="searchInput"
      color="white"
      item-text="profile.real_name"
      item-value="user_id"
      label="Team members"
      placeholder="Select team member"
      no-data-text="Search by name"
      return-object
      solo
      dense
      clearable
    >
      <template v-slot:item="data">
        <img class="user-icon" :src="data.item.profile.image_48">
        <v-list-item-title class="ml-1" v-html="data.item.profile.real_name"></v-list-item-title>
      </template>
      <template v-slot:selection="data">
        <img class="user-icon" :src="data.item.profile.image_48">
        <v-list-item-title class="ml-1" v-html="data.item.profile.real_name"></v-list-item-title>
      </template>
    </v-autocomplete>

    <br/>
    <div v-if="feedbackReceived != null && feedbackReceived.length == 0" style="font-size: 16px;">
      Nothing here yet.
    </div>
    <div v-else v-for="(feedback, idx) in feedbackReceived" :key="idx" class="feedback-card mb-3 px-5 py-5">
      <FeedbackCard
        :feedback="feedback"
      ></FeedbackCard>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import { Api } from '@/api/Api'
import FeedbackCard from '../components/FeedbackView/FeedbackCard.vue'

export default {
  name: 'MyTeam',
  components: {
    FeedbackCard
  },
  props: {
  },
  data () {
    return {
      feedbackReceived: null,
      autocompleteModel: null,
      teamMembers: [],
      searchInput: null
    }
  },
  computed: {
    feedback_types() {
      return FEEDBACK_TYPES
    }
  },
  watch: {
    autocompleteModel (newVal, oldVal) {
      if (newVal != null) {
        this.fetchFeedbackForReport(newVal)
      } else {
        this.fetchFeedbackForAllReports()
      }
    },
  },
  methods: {
    fetchFeedbackForReport (report) {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-feedback-for-report',
        {
          method: 'post',
          data: {
            report_id: report.user_id
          },
          withCredentials: true
        }
      ).then((response) => {
        this.feedbackReceived = response.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    fetchFeedbackForAllReports () {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-feedback-for-all-reports',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        this.feedbackReceived = response.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    fetchTeamMembers () {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-direct-reports',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        this.teamMembers = response.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    }
  },
  mounted: function () {
    // this.fetchFeedbackReceived()
    this.fetchTeamMembers()
    this.fetchFeedbackForAllReports()
  },
  filters: {
  }
}
</script>

<style scoped>
  .feedback-card-header {
    font-weight: 500;
  }

  .feedback-card {
    text-align: left;
    border: 1px solid lightgray;
    border-radius: 5px;
  }
</style>
