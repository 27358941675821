<template>
  <div>
    <div class="mt-3 mb-2">
      <h2>{{ feedback.subject }}</h2>
    </div>
    <div class="feedback-content"> 
      <h3>☝️ Start</h3>
      <p v-for="(paragraph, idx) in feedback.content.start_text.split('\n')" :key="idx">{{ paragraph }} </p>
    </div>
    <div class="feedback-content"> 
      <h3>✋ Stop</h3>
      <p v-for="(paragraph, idx) in feedback.content.stop_text.split('\n')" :key="idx">{{ paragraph }} </p>
    </div>
    <div class="feedback-content"> 
      <h3>🙌 Continue</h3>
      <p v-for="(paragraph, idx) in feedback.content.continue_text.split('\n')" :key="idx">{{ paragraph }} </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StartStopContinue',
  components: {
  },
  props: {
    feedback: Object
  },
  data () {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
  mounted: function () {
  },
  filters: {
  }
}
</script>

<style scoped>

</style>