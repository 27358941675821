
<template>
  <v-container fluid class="main-container" >
    <h1>🌟<br/>Wall of Praise</h1>
    <br/>
    <div v-if="praise != null && praise.length == 0" style="font-size: 16px;">
      Nothing here yet.
    </div>
    <div v-else v-for="(feedback, idx) in praise" :key="idx" class="feedback-card mb-3 px-5 py-5">
      <FeedbackCard
        :feedback="feedback"
      ></FeedbackCard>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import { Api } from '@/api/Api'
import FeedbackCard from '../components/FeedbackView/FeedbackCard.vue'

export default {
  name: 'PraiseWall',
  components: {
    FeedbackCard
  },
  props: {
  },
  data () {
    return {
      praise: null
    }
  },
  computed: {
    feedback_types() {
      return FEEDBACK_TYPES
    }
  },
  watch: {
  },
  methods: {
    fetchPraiseWall() {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-praise-wall',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        this.praise = response.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    }
  },
  mounted: function () {
    this.fetchPraiseWall()
  },
  filters: {
  }
}
</script>

<style scoped>
  .feedback-card-header {
    font-weight: 500;
  }

  .user-icon-recipient {
    height: 18px;
    border-radius: 3px;
    vertical-align: text-bottom;
  }

</style>
