<template>
  <v-container
    v-if="$store.state.userData.is_admin == true"
    fluid style="max-width: 800px; width: 90%;"
  >
    <div class="text-left" v-if="settings != null">
      <h2 class="mt-5">Praise</h2>    
      <v-row align="center" >
        <v-col md6>
          Default channel to share praise
        </v-col>
        <v-col md6>
          <v-autocomplete
            class="mt-3"
            v-model="defaultPraiseChannel"
            :items="channels"
            color="white"
            item-text="name"
            item-value="id"
            label="Channels"
            placeholder="Select channel"
            no-data-text="Search by channel name"
            return-object
            solo
            dense
            @change="updateDefaultPraiseChannel()"
          >
            <template v-slot:item="data">
              <v-list-item-title class="" ><b>{{ data.item.name }}</b></v-list-item-title>
            </template>
            <template v-slot:selection="data">
              <v-list-item-title class="" ><b>{{ data.item.name }}</b></v-list-item-title>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </div>
    <div class="text-left" v-if="settings != null">
      <v-row align="center" >
        <v-col md6>
          Enable public Wall of Praise
        </v-col>
        <v-col md6>
          <v-switch
            v-if="settings != null"
            v-model="publicPraiseWallEnabled"
            @change="togglePublicPraiseWall()"
            style="width: 20px"
          >
          </v-switch>
        </v-col>
      </v-row>
      <v-row v-if="publicPraiseWallEnabled">
        <v-col>
          <p>Wall of Praise public url:</p>
          <v-text-field
            outlined
            readonly
            dense
            v-if="publicPraiseWallEnabled"
            v-model="publicPraiseWallUrl"
            style="color: black!important;"
            ref="publicPraiseWallUrlField"
          >
            <template v-slot:append-outer>
              <v-btn 
                icon
                small
                @click="copyPraiseWallUrl()">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
          </v-text-field>

        </v-col>
      </v-row>
    </div>

    <div class="text-left">
      <h2 class="mt-5">Values</h2>    
    </div>
    <v-simple-table class="text-left">
      <template v-slot:default>
        <tbody>
          <tr v-for="(value, idx) in values" :key="idx">
            <td><b>{{ value.value }}</b></td>
            <td>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon @click="deleteValueConfirm(value)">mdi-minus-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>Delete value</span>
            </v-tooltip>
            </td>
          </tr>
          <tr>
            <td>
              <v-btn 
                small
                @click="addValueDialogVisible=true"
              >
                <v-icon>mdi-plus</v-icon> Add value
              </v-btn>
            </td>
            <td></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog
      v-model="addValueDialogVisible"
      max-width="500px"
    >
      <v-card
        v-if="addValueDialogVisible"
      >
        <v-card-title>
          Add value
          <v-spacer></v-spacer>
          <v-btn icon small @click="addValueDialogVisible=false"><v-icon>mdi-close</v-icon></v-btn>

        </v-card-title>
        <v-card-text style="color: black;">           
          <v-form
            ref="newValueForm"
          >
            <v-text-field
              solo
              style="font-weight: bold"
              v-model="newValueText"
              required
              :rules="[v => !!v || 'Value is required']"
            ></v-text-field>
          </v-form>
          <b>☝️Hint:</b> To make them look super nice, enter values as hashtags (eg. <b>#leadership</b>) or with an <a href='https://emojipedia.org/' target="_blank">emoji</a> (eg. <b>🚀get-things-done</b>).
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="addValueSubmit()">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="valueToDelete != null"
      v-model="deleteValueConfirmDialogVisible"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Confirm delete
        </v-card-title>
        <v-card-text style="color: black;">
          Delete <b>{{ valueToDelete.value }}</b>?
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="deleteValueConfirmDialogVisible=false">Cancel</v-btn>
          <v-btn @click="deleteValue(valueToDelete)" color="error">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      app
      bottom right
      timeout="800"
      color="info"
      v-model="showSnackbar"
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import { Api } from '@/api/Api'

export default {
  name: 'AdminValues',
  components: {
  },
  props: {
  },
  data () {
    return {
      values: [],

      addValueDialogVisible: false,
      newValueText: null,

      deleteValueConfirmDialogVisible: false,
      valueToDelete: null,

      settings: null,
      publicPraiseWallEnabled: null,

      defaultPraiseChannel: null,
      channels: [],

      showSnackbar: false,
      snackbarText: null
    }
  },
  computed: {
    publicPraiseWallUrl () {
      let route = window.location.origin + '/praise-wall-public/' + this.settings.praise_wall_url_token
      return route
    }
  },
  watch: {
    addValueDialogVisible(newVal) {
      if (newVal === true) {
        this.newValueText = null
      }
    }
  },
  methods: {
    fetchValues() {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-all-values',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        this.values = response.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    addValueSubmit() {
      if (this.$refs.newValueForm.validate()) {
        this.$store.state.appBarProgressActive = true
        axios(
          Api.baseUrl + '/add-value',
          {
            method: 'post',
            data: { value: this.newValueText },
            withCredentials: true
          }
        ).then((response) => {
          this.addValueDialogVisible = false
          this.fetchValues()
        }).catch((error) => {
          console.log(error)
          this.$store.state.appBarProgressActive = false
        }).finally(() => {          
        })
      }
    },
    deleteValueConfirm (value) {
      this.valueToDelete = value
      this.deleteValueConfirmDialogVisible = true
    },
    deleteValue (value) {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/delete-value',
        {
          method: 'post',
          data: { value_id: value.id },
          withCredentials: true
        }
      ).then((response) => {
        this.deleteValueConfirmDialogVisible = false
        this.fetchValues()
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
      })
    },

    fetchSettings() {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-settings',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        this.settings = response.data
        this.publicPraiseWallEnabled = this.settings.praise_wall_enabled
        this.defaultPraiseChannel = this.settings.praise_default_channel

        this.channels.map((channel) => {
          if (channel.id == this.settings.praise_default_channel) {
            this.defaultPraiseChannel = channel
          }
        })
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },

    togglePublicPraiseWall () {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/toggle-public-praise-wall',
        {
          method: 'post',
          data: { state: this.publicPraiseWallEnabled },
          withCredentials: true
        }
      ).then((response) => {        
        this.fetchSettings()
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    copyPraiseWallUrl () {
      let textToCopy = this.$refs.publicPraiseWallUrlField.$el.querySelector('input')
      textToCopy.select()
      document.execCommand("copy");    
      this.snackbarText = 'Copied to clipboard.'
      this.showSnackbar = true
    },
    fetchChannels () {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-public-channels',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        for (let i=0; i < response.data.length; i++){
          response.data[i].name = '#' + response.data[i].name
        }
        this.channels = [
          {
            'id': null,
            'name': 'None'
          },
          ...response.data
        ]
        
        this.channels.map((channel) => {
          if (channel.id == this.settings.praise_default_channel) {
            this.defaultPraiseChannel = channel
          }
        })
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    updateDefaultPraiseChannel () {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/set-default-praise-channel',
        {
          method: 'post',
          data: { channel_id: this.defaultPraiseChannel.id },
          withCredentials: true
        }
      ).then((response) => {
        this.fetchSettings()
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    }
  },
  mounted: function () {
    this.fetchSettings()
    this.fetchValues()
    this.fetchChannels()
  },
  filters: {
  }
}
</script>

<style scoped>

</style>
