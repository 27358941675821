<template>
  <v-container fluid class="main-container" >
    <v-app-bar
      app
      dense
      flat
      clipped-left
    >
      <router-link
        class="site-name" to="/" tag="span" style="cursor: pointer"
      >
        <a class="header-link">
          <img style="height: 32px; border-radius: 5px; margin-top: 7px;" src='/static/frank-logo.jpg'/>
        </a>
      </router-link>
      <div style="display: inline-block; font-weight: 600; font-size: 20px;" class="ml-0 pb-0"> I'm Frank</div>
      <v-spacer></v-spacer>
      <span style="font-size: 32px">🌟</span><div style="display: inline-block; font-weight: 600; font-size: 20px;" class="ml-2 pb-0">Wall of Praise</div>
    </v-app-bar>
    <br/>
    <v-layout wrap justify-center>
      <div
        v-for="(feedback, idx) in praise" :key="idx" 
      > 
        <div class="feedback-card my-3 px-5 py-5 mx-3">
          <FeedbackCard
            :feedback="feedback"
          ></FeedbackCard>
        </div>
      </div>
    </v-layout>
    <div v-if="errorMessage">{{ errorMessage }}</div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import { Api } from '@/api/Api'
import FeedbackCard from '../components/FeedbackView/FeedbackCard.vue'

export default {
  name: 'PraiseWallPublic',
  components: {
    FeedbackCard
  },
  props: {
  },
  data () {
    return {
      praise: null,
      errorMessage: null
    }
  },
  computed: {
    feedback_types() {
      return FEEDBACK_TYPES
    }
  },
  watch: {
  },
  methods: {
    fetchPraiseWall() {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-praise-wall-public',
        {
          method: 'post',
          data: {
            token: this.$route.params.token
          },
          withCredentials: true
        }
      ).then((response) => {
        if (response.data.ok) {
          this.praise = response.data.praise
          this.errorMessage = null
        } else {
          this.errorMessage = response.data.error
        }
      }).catch((error) => {
        this.errorMessage = "Can't load praise wall. Please make sure you have the correct url."
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    }
  },
  mounted: function () {
    this.$store.state.appBarOverride = true
    this.fetchPraiseWall()
  },
  filters: {
  }
}
</script>

<style scoped>
  .main-container {
      text-align: center;
      max-width: 100%!important;
      width: 95%!important;
  }

  .feedback-card-header {
    font-weight: 500;
  }

  .user-icon-recipient {
    height: 18px;
    border-radius: 3px;
    vertical-align: text-bottom;
  }

  .feedback-card {
    text-align: left;
    border: 1px solid lightgray;
    border-radius: 5px;
    max-width: 500px;
  }
</style>
