<template>
  <v-container
    v-if="$store.state.userData.is_admin == true"
    fluid style="max-width: 800px; width: 90%;"
  >
    <div class="text-left">
      <h2 class="mt-5">Admins</h2>    
    </div>
    <v-simple-table class="text-left">
      <template v-slot:default>
        <tbody>
          <tr v-for="(admin, idx) in admins" :key="idx">
            <td>
              <img class="user-icon" :src="admin.user_info.profile.image_32"/> {{ admin.user_info.profile.real_name }}
              </td>
            <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon @click="confirmDelete(admin)">mdi-minus-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>Remove from admins</span>
            </v-tooltip>
            </td>
          </tr>
          <tr>
            <td>
              <v-btn 
                small
                @click="addAdminsDialogVisible=true"
              >
                <v-icon>mdi-plus</v-icon> Add admin 
              </v-btn>
            </td>
            <td></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog
      v-model="addAdminsDialogVisible"
      max-width="600px"
      overlay-opacity="0.8"
      scrollable
      persistent
    >
      <v-card >
        <v-progress-linear
          :active="$store.state.appBarProgressActive"
          :indeterminate="$store.state.appBarProgressActive"
          absolute
          color="orange"
        ></v-progress-linear> 
        <v-card-title>
          Add admins
          <v-spacer/>
          <a
            class="back-button"
            @click="addAdminsDialogVisible = false"
          ><v-icon>mdi-close</v-icon>
          </a>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            class="mt-3"
            v-model="newAdmins"
            :items="allPeople"
            item-text="profile.real_name"
            item-value="user_id"
            label="Find people to add"
            placeholder="People"
            no-data-text="Search by name"
            return-object
            solo
            dense
            chips
            small-chips
            multiple
          >
            <template v-slot:item="data">
              <img class="user-icon" :src="data.item.profile.image_48">
              <v-list-item-title class="ml-1" v-html="data.item.profile.real_name"></v-list-item-title>
            </template>
            <template v-slot:selection="data">
              <v-chip
                class="my-1 ml-0 mr-1"
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                label
                color="rgb(245, 245, 245)"
                @click="data.select"
                @click:close="remove(data.item)"
              >
                <img class="user-icon mr-1" :src="data.item.profile.image_32"/>
                {{ data.item.profile.real_name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-card-actions class="justify-end mr-2 mb-3" >
          <v-btn
            color="primary"
            @click="addAdmins()"
            >Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="adminToDelete != null"
      v-model="deleteAdminConfirmDialogVisible"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Please confirm
        </v-card-title>
        <v-card-text style="color: black;">
          Remove <b>{{ adminToDelete.user_info.real_name }}</b> from admins?
          <div class="mt-1" style="color: red" v-if="errorText !=null"><b>{{ errorText }}</b></div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="deleteAdminConfirmDialogVisible=false">Cancel</v-btn>
          <v-btn @click="removeAdmin(adminToDelete)" color="error">Remove</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import { Api } from '@/api/Api'

export default {
  name: 'AdminAdmins',
  components: {
  },
  props: {
  },
  data () {
    return {
      admins: [],
      addAdminsDialogVisible: false,

      allPeople: null,
      newAdmins: null,
      deleteAdminConfirmDialogVisible: null,
      adminToDelete: null,
      errorText: null,
    }
  },
  computed: {
  },
  watch: {
    addAdminsDialogVisible(newValue) {
      if (newValue === true) {
        this.fetchAllPeople()
        this.newAdmins = null
      }
    },
    deleteAdminConfirmDialogVisible() {
      this.errorText = null
    }
  },
  methods: {
    fetchAdmins () {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-admins',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        response.data.sort((a, b) => {
          if (a.user_info.profile.real_name < b.user_info.profile.real_name) {
            return -1
          } else if (a.user_info.profile.real_name > b.user_info.profile.real_name) {
            return 1
          } else {
            return 0
          }
        })
        this.admins = response.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    addAdmins () {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/add-admins',
        {
          method: 'post',
          data: { users: this.newAdmins },
          withCredentials: true
        }
      ).then((response) => {
        this.fetchAdmins()
      }).catch((error) => {
        console.log(error)
        this.$store.state.appBarProgressActive = false
      }).finally(() => {
        this.addAdminsDialogVisible = false
      })
    },
    removeAdmin (admin) {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/remove-admin',
        {
          method: 'post',
          data: { user_id: admin.id },
          withCredentials: true
        }
      ).then((response) => {
        if (response.data.ok === true) {
          this.fetchAdmins()
          this.deleteAdminConfirmDialogVisible = false
        } else {
          this.errorText = response.data.error
        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.$store.state.appBarProgressActive = false
      })
    },
    fetchAllPeople() {
      this.allPeople = []
      axios(
        Api.baseUrl + '/get-all-users',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        response.data.sort((a, b) => {
          if (a.profile.real_name < b.profile.real_name) {
            return -1
          } else if (a.profile.real_name > b.profile.real_name) {
            return 1
          } else {
            return 0
          }
        })
        this.allPeople = response.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    remove (item) {
      let ind = this.newAdmins.indexOf(item)
      if (ind >= 0) {
        this.newAdmins.splice(ind, 1)
      }
    },
    confirmDelete (admin) {
      this.adminToDelete = admin
      this.deleteAdminConfirmDialogVisible = true
    }
  },
  mounted: function () {
    this.fetchAdmins()
  },
  filters: {
  }
}
</script>

<style scoped>

</style>
