<template>
  <div
    v-if="$store.state.loggedIn === true"
    id="app"
  >
    <v-app>
      <v-app-bar
        app
        flat
        clipped-left
        v-if="!$store.state.appBarOverride"
      >
        <router-link
          class="site-name" to="/" tag="span" style="cursor: pointer"
        >
          <a class="header-link">
            <img style="height: 40px; border-radius: 5px; margin-top: 7px;" src='/static/frank-logo.jpg'/>
          </a>
        </router-link>
        <div style="display: inline-block; font-weight: 600; font-size: 20px;" class="ml-0 pb-0"> I'm Frank</div>
        
        <v-spacer></v-spacer>

        <v-menu
          v-if="loggedIn"
          offset-y
          bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                class="article-menu-button"
              >
                <img class="user-icon" :src="$store.state.userData.profile.image_48"/>
                <span
                  v-if="!$vuetify.breakpoint.mobile"
                >
                  <!-- &nbsp;{{ $store.state.userData.name }} -->
                </span>
              </v-btn>
            </template>
          <v-list dense>
            <v-list-item
              @click="logout(this)"
              v-if="loggedIn"
            >
              <v-list-item-icon><v-icon>mdi-exit-to-app</v-icon></v-list-item-icon>
              <v-list-item-title>Sign out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-progress-linear
          :active="$store.state.appBarProgressActive"
          :indeterminate="$store.state.appBarProgressActive"
          absolute
          bottom
          color="orange"
        ></v-progress-linear> 
      </v-app-bar>
      <v-main>
        <router-view></router-view>
      </v-main>

      <NavigationDrawer
        :visible="showDrawer"
        :compact='false'
        v-if="!$store.state.appBarOverride"
      ></NavigationDrawer>
      <v-bottom-navigation
        app
        v-if="$vuetify.breakpoint.mobile"
      >
        <v-btn to="/">
          <span>Received</span>
          <v-icon>mdi-email-receive</v-icon>
        </v-btn>
        <v-btn to="/feedback-sent">
          <span>Sent</span>
          <v-icon>mdi-email-send</v-icon>
        </v-btn>
        <v-btn to="/my-team" v-if="$store.state.userData.has_reports">
          <span>Your Team</span>
          <v-icon>mdi-account-group</v-icon>
        </v-btn>
        <v-btn @click="showDrawer = !showDrawer">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-bottom-navigation>
  </v-app>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import NavigationDrawer from '../components/NavigationDrawer.vue'

export default {
  components: {
    NavigationDrawer
  },
  data: function () {
    return {
      showDrawer: false,
      searchBoxVisible: false
    }
  },
  computed: {
    loggedIn: function () {
      return this.$store.state.loggedIn
    },
  },
  methods: {
    ...mapActions({
      checkLoggedIn: 'checkLoggedIn',
      logoutAction: 'logout'
    }),
    logout () {
      this.logoutAction(this)
        .then(() => {
          this.$router.push('/')
          location.reload()
        })
    },
  },
  mounted () {
    if (!this.$vuetify.breakpoint.mobile) {
      this.showDrawer = true
    }
    this.checkLoggedIn()
  }
}
</script>

<style scoped>
  .footer {
    text-align: center;
    width: 100%;
  }

  .footer a {
    text-decoration: none;
    color: black!important;
    font-size: 12px;
  }

  .footer a:visited {
    color: black!important;
  }

  .search-box {
    margin-left: auto;
    margin-right: auto;
  }

  a.header-link {
    text-decoration: none;
    color: black!important;
  }

  a.header-link:visited {
    text-decoration: none;
    color: black!important;
  }

  a.header-link-right {
    font-size: 16px;
    font-weight: 400;
  }

  a.header-link .v-icon {
    font-size: 36px!important;
  }

  img.user-icon {
    border-radius: 5px;
    height: 40px;
  }

</style>

<style>
  .main-container {
      /* min-height: 100vh; */
      text-align: center;
      max-width: 600px!important;
      width: 95%!important;
  }

  .user-chip {
    background-color: rgb(247, 247, 247);
    display: inline-block;
    padding: 3px 5px;
    border-radius: 3px;
  }

  .user-icon, .user-chip img {
    height: 18px;
    border-radius: 3px;
    vertical-align: text-bottom;
  }

  .anon-emoji {
    font-size: 18px;
    vertical-align: middle;
  }

  hr {
    height: 1px!important;
    background-color: lightgray;
    border: none;
  }

  .feedback-card {
    text-align: left;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px;
    border-radius: 5px;
    margin-top: 25px;
  }
</style>
