
<template>
  <div>
    <div class="feedback-card-header pb-2">
      <div>
        <b>From: </b>
        <div class="user-chip" v-if="!feedback.anonymous">
          <img class="user-icon" :src="feedback.author.profile.image_48" />
          <span class="ml-1">{{ feedback.author.real_name }}</span>
        </div>
        <div class="user-chip" v-else>
          <span class="anon-emoji">🥸</span><span class=""> Anonymous</span>
        </div>
        on {{ feedback.create_time | moment("MMM DD, YYYY") }}
      </div>
      <div class="mt-1">
        <b>To: </b>
        <div class="user-chip mr-1 my-1" v-for="(recipient, rec_idx) in feedback.recipients" :key="rec_idx"
        >
            <img :src="recipient.profile.image_32" class="user-icon-recipient mr-1"/> <span>{{ recipient.profile.real_name }}</span>
        </div>
      </div>
    </div>
    <OpenFeedback :feedback="feedback" v-if="feedback.feedback_type==feedback_types.OPEN_FEEDBACK"></OpenFeedback>
    <StartStopContinue :feedback="feedback" v-if="feedback.feedback_type==feedback_types.START_STOP_CONTINUE"></StartStopContinue>
    <SituationBehaviorImpact :feedback="feedback" v-if="feedback.feedback_type==feedback_types.SITUATION_BEHAVIOR_IMPACT"></SituationBehaviorImpact>
    <NVC :feedback="feedback" v-if="feedback.feedback_type==feedback_types.NVC"></NVC>
    <Praise :feedback="feedback" v-if="feedback.feedback_type==feedback_types.PRAISE"></Praise>

    <div class="feedback-card-footer text-right" v-if="feedback.feedback_type!=feedback_types.PRAISE" >
      Visible to:
      <div style="display: inline-block">
        <v-icon v-if="feedback.visible_to_recipient" class="visibility-icon">mdi-checkbox-marked-outline</v-icon>
        <v-icon v-else class="visibility-icon">mdi-checkbox-blank-outline</v-icon>
        <span v-for="(recipient, rec_idx) in feedback.recipients" :key="rec_idx"> {{ recipient.id === $store.state.userData.id ? 'You' : recipient.profile.real_name }} </span>
      </div>
      <div style="display: inline-block" class="ml-3">
        <v-icon v-if="feedback.visible_to_manager" class="visibility-icon">mdi-checkbox-marked-outline</v-icon>
        <v-icon v-else class="visibility-icon">mdi-checkbox-blank-outline</v-icon>
        <span v-if="feedback.recipients.length > 1">Their manager</span>
        <span v-else> {{ feedback.recipients[0].id === $store.state.userData.id ? 'Your manager' : feedback.recipients[0].profile.real_name + "'s manager" }}</span>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import { Api } from '@/api/Api'
import OpenFeedback from './OpenFeedback.vue'
import StartStopContinue from './StartStopContinue.vue'
import SituationBehaviorImpact from './SituationBehaviorImpact'
import NVC from './NVC'
import Praise from './Praise'
import { FEEDBACK_TYPES } from '../../Constants'

export default {
  name: 'FeedbackCard',
  components: {
    OpenFeedback,
    StartStopContinue,
    SituationBehaviorImpact,
    NVC,
    Praise,
  },
  props: {
    feedback: Object
  },
  data () {
    return {
    }
  },
  computed: {
    feedback_types() {
      return FEEDBACK_TYPES
    }
  },
  watch: {
  },
  methods: {
  },
  mounted: function () {
  },
  filters: {
  }
}
</script>

<style>
  .feedback-content {
    font-size: 15px;
    font-weight: 400;
  }

</style>

<style scoped>
  .feedback-card-header {
    font-weight: 400;
    border-bottom: 1px solid rgb(240, 240, 240);
  }

  .feedback-card {
    text-align: left;
    border: 1px solid lightgray;
    border-radius: 5px;
  }


  .feedback-card-footer {
    font-size: 12px;
    color: gray;
  }

  .visibility-icon {
    font-size: 12px!important;
    color: gray;
  }
</style>
