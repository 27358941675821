
<template>
  <v-container
    v-if="$store.state.userData.is_admin == true"
    fluid style="text-align: center; max-width: 800px; width: 90%;"
  >
    <div style="text-align: left">
        <v-treeview
          :items="org"
          expand-icon="mdi-chevron-down"
          item-children="sub_org"
          item-text="user_info.real_name"
          transition
          hoverable
          open-all
          ref="treeview"
        >
          <template v-slot:prepend="{ item }">
            <img class="user-icon" :src="item.user_info.profile.image_32"/>
          </template>
          <template v-slot:append="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="item.sub_org.length == 0 ? deleteSubordinate(item) : deleteSubordinateConfirm(item)"
                  v-if="item.user_id != -1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="">mdi-minus-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>Remove {{ item.user_info.profile.real_name }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="editSubordinatesClicked(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="">mdi-account-multiple-plus</v-icon>
                </v-btn>
              </template>
              <span>Add direct reports</span>
            </v-tooltip>
          </template>
        </v-treeview>
    </div>
    <EditSubordinatesDialog
      :showLoginToggle="editSubordinatesDialogVisible"
      :manager="selectedUser"
      @orgChartUpdated="fetchOrgChart()"
    ></EditSubordinatesDialog>
    <v-dialog
      v-model="deleteSubordinateConfirmDialogVisible"
      max-width="500px"
    >
      <v-card
        v-if="subordinateToDelete != null"
      >
        <v-card-title>
          Confirm delete
        </v-card-title>
        <v-card-text>
          Delete
          <div class="user-chip">
            <img :src="subordinateToDelete.user_info.profile.image_48"/> {{ subordinateToDelete.user_info.profile.real_name }}
          </div> with their entire sub-org?
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="deleteSubordinateConfirmDialogVisible=false">Cancel</v-btn>
          <v-btn @click="deleteSubordinate(subordinateToDelete)" color="error">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import { Api } from '@/api/Api'
import EditSubordinatesDialog from '../components/OrgChart/EditSubordinatesDialog.vue'

export default {
  name: 'OrgChart',
  components: {
    EditSubordinatesDialog
  },
  props: {
  },
  data () {
    return {
      org: [],
      editSubordinatesDialogVisible: false,
      selectedUser: null,
      deleteSubordinateConfirmDialogVisible: false,
      subordinateToDelete: null
    }
  },
  computed: {
  },
  watch: {

  },
  methods: {
    fetchTeamInfo () {
      this.$store.state.appBarProgressActive = true
      return new Promise((resolve, reject) => {
        axios(
          Api.baseUrl + '/get-team-info',
          {
            method: 'post',
            data: {},
            withCredentials: true
          }
        ).then((response) => {
          this.teamInfo = response.data
          resolve()
        }).catch((error) => {
          reject(erorr)
        }).finally(() => {
        })
      })
    },
    fetchOrgChart () {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-org-chart',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        this.org = [{
          user_info: {
            real_name: this.teamInfo.team.name,
            profile: {
              real_name: this.teamInfo.team.name,
              image_32: this.teamInfo.team.icon.image_44,
              image_48: this.teamInfo.team.icon.image_102
            }
          },
          user_id: -1,
          sub_org: response.data
        }]
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
        this.$refs.treeview.updateAll(true)
      })
    },
    editSubordinatesClicked(user) {
      this.selectedUser = user
      this.editSubordinatesDialogVisible = !this.editSubordinatesDialogVisible
    },
    deleteSubordinate(user) {
      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/delete-direct-reports',
        {
          method: 'post',
          data: {
            manager_id: user.parent_id,
            reports: [user]
          },
          withCredentials: true
        }
      ).then((response) => {
        this.fetchOrgChart()
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.deleteSubordinateConfirmDialogVisible = false
        // this.$store.state.appBarProgressActive = false
      })
    },
    deleteSubordinateConfirm (user) {
      this.subordinateToDelete = user
      this.deleteSubordinateConfirmDialogVisible = true
    }
  },
  mounted: function () {
    this.fetchTeamInfo().then(() => {
      this.fetchOrgChart()
    })
  },
  filters: {
  }
}
</script>

<style scoped>
  .feedback-card-header {
    font-weight: 500;
  }

  .user-chip {
    background-color: rgb(245, 245, 245);
    display: inline-block;
    padding: 5px 6px;
    border-radius: 5px;
  }

  .user-icon {
    height: 24px;
    border-radius: 3px;
    vertical-align: bottom;
  }

  .feedback-card {
    text-align: left;
    border: 1px solid lightgray;
    border-radius: 5px;
  }
</style>
