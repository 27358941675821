<template>
  <v-dialog
    v-model="dialogVisible"
    max-width="600px"
    overlay-opacity="0.8"
    scrollable
    persistent
  >
    <v-card >
      <v-progress-linear
        :active="$store.state.appBarProgressActive"
        :indeterminate="$store.state.appBarProgressActive"
        absolute
        color="orange"
      ></v-progress-linear> 
      <v-card-title>
        Add Users 
        <v-spacer/>
        <a
          class="back-button"
          @click="dialogVisible = false"
        ><v-icon>mdi-close</v-icon>
        </a>
      </v-card-title>
      <v-card-text class="card-text">
        <div class="mt-2" style="color: black">
          <div v-if="selectedUsers.length > 0 ? true : false">{{ newUsers.length }} new users</div>
          <div v-if="(newUsers.length > 0 && !prorateInfo.prorate_applies) ? true : false">
            x ${{ planInfo.monthly_price_per_user | numeral('0.00') }} per user / month
            <hr class="my-2"/>
            <b>Total <span v-if="planInfo.subscription_exists">additional</span> cost: ${{ (newUsers.length * planInfo.monthly_price_per_user) | numeral('0.00') }}/month</b>
          </div>
          <div v-if="(newUsers.length > 0 && prorateInfo.prorate_applies) ? true : false">
            x ${{ planInfo.monthly_price_per_user | numeral('0.00') }} per user / month
            <hr class="my-2"/>
            <b>Total <span v-if="planInfo.subscription_exists">additional</span> cost:</b> <br/>
            <b>${{ (newUsers.length * prorateInfo.prorated_price_per_user) | numeral('0.00') }} one-time payment, prorated for {{ prorateInfo.days_until_next_payment }} days</b> until {{ planInfo.next_payment_time | moment("MMMM DD, YYYY")}}<br/>
            <b>${{ (newUsers.length * planInfo.monthly_price_per_user) | numeral('0.00') }}/month</b> after {{ planInfo.next_payment_time | moment("MMMM DD, YYYY")}}
          </div>

        </div>
          <v-container class="mt-5">
            <v-row class="text-xs" justify="space-between">
              <!-- <span>{{ selectedUsers === null ? 0 : selectedUsers.length }} users selected</span> -->
              <v-spacer></v-spacer>
              <span>
                <v-btn text small @click="selectAll()"><v-icon class="mr-1">mdi-checkbox-multiple-marked-outline</v-icon>Add all</v-btn>
                <v-btn text small @click="selectNone()"><v-icon class="mr-1">mdi-checkbox-multiple-blank-outline</v-icon>Clear</v-btn>
              </span>
            </v-row>
          </v-container>
          <v-autocomplete
            class="mt-3"
            v-model="selectedUsers"
            :items="allPeople"
            item-text="profile.real_name"
            item-value="user_id"
            label="Find people to add"
            placeholder="Find people to add"
            no-data-text="Search by name"
            return-object
            solo
            dense
            chips
            small-chips
            multiple
          >
            <template v-slot:item="data">
              <img class="user-icon" :src="data.item.profile.image_48">
              <v-list-item-title class="ml-1" v-html="data.item.profile.real_name"></v-list-item-title>
            </template>
            <template v-slot:selection="data">
              <v-chip
                class="my-1 ml-0 mr-1"
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                label
                :color="isInSubscription(data.item) === false ? '#d2f8d2' : 'rgb(245, 245, 245)'"
                @click="data.select"
                @click:close="remove(data.item)"
              >
                <img class="user-icon mr-1" :src="data.item.profile.image_32"/>
                {{ data.item.profile.real_name }}
              </v-chip>
            </template>
          </v-autocomplete>
          <div v-if="selectedUsers.length > 0 && planInfo.subscription_exists">
            <div>
              <v-icon color='#d2f8d2'>mdi-checkbox-blank</v-icon> New user
            </div>
            <div>
              <v-icon color='rgb(245, 245, 245)'>mdi-checkbox-blank</v-icon> Already in subscription 
            </div>
          </div>
      </v-card-text>
      <v-card-actions class="justify-end mr-2 mb-3" >
        <v-btn
          v-if="planInfo.subscription_exists === false"
          color="primary"
          :disabled="selectedUsers.length > 0 ? false : true"
          @click="$emit('userSelectionDone', selectedUsers)"
        >
          Payment details ➞
        </v-btn>
        <v-btn
          v-else
          color="primary"
          :disabled="selectedUsers.length > 0 ? false : true"
          @click="$emit('userSelectionDone', selectedUsers)"
        >
          Review &amp; Billing info ➞
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import { Api } from '@/api/Api'

export default {
  name: 'SubscriptionUsersDialog',
  components: {
  },
  props: {
    showDialogToggle: {
      type: Boolean,
      default: false
    },
    planInfo: Object,
    prorateInfo: Object
  },
  data () {
    return {
      dialogVisible: false,

      allPeople: null,
      selectedUsers: []
    }
  },
  computed: {
    newUsers: function () {
      let users = []
      this.selectedUsers.map((user) => {
        if(this.isInSubscription(user) === false) {
          users.push(user)
        }
      })
      return users
    }
  },
  watch: {
    showDialogToggle (oldVal, newVal) {
      this.dialogVisible = !this.dialogVisible
      this.showError = false
    },
    dialogVisible (newVal, oldVal) {
      if (oldVal == false && newVal == true) {
        this.selectedUsers = []
        this.fetchAllPeople()
      }
    },
  },
  methods: {
    fetchAllPeople() {
      this.allPeople = []

      this.$store.state.appBarProgressActive = true
      axios(
        Api.baseUrl + '/get-all-users',
        {
          method: 'post',
          data: {},
          withCredentials: true
        }
      ).then((response) => {
        response.data.sort((a, b) => {
          if (a.profile.real_name < b.profile.real_name) {
            return -1
          } else if (a.profile.real_name > b.profile.real_name) {
            return 1
          } else {
            return 0
          }
        })
        this.allPeople = response.data
        // for (let i = 0; i < 10; i++){
        //   this.allPeople.push(...response.data)
        // }

        // this.selectedUsers = this.allPeople
      }).catch((error) => {
        console.log(error)
      }).finally(() => {          
        this.$store.state.appBarProgressActive = false
      })
    },
    selectAll () {
      this.selectedUsers = [...this.allPeople]
    },
    selectNone () {
      this.selectedUsers = []
    },
    isInSubscription (user) {
      if(this.planInfo.users_in_subscription == undefined || !this.planInfo.subscription_exists) {
        return false
      }

      for (let i = 0; i < this.planInfo.users_in_subscription.length; i++) {
        if (user.id===this.planInfo.users_in_subscription[i].user_info['id']) {
          return true;
        }
      }
      return false;
    },
    remove (item) {
      let ind = this.selectedUsers.indexOf(item)
      if (ind >= 0) {
        this.selectedUsers.splice(ind, 1)
      }
    },
  },
  mounted: function () {
  },
  filters: {
  }
}
</script>

<style scoped>
  .user-chip {
    background-color: rgb(245, 245, 245);
    display: inline-block;
    padding: 5px 6px;
    border-radius: 5px;
  }

  .user-icon {
    height: 18px;
    border-radius: 3px;
    vertical-align: bottom;
  }

  a.back-button {
    font-size: 12px;
  }

  .card-text {
    color: black!important;
  }
</style>

